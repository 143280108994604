<app-header
  [isSideNavCollapsed]="isSideNavCollapsed"
  (onToggleSidenav)="onToggleSidenav($event)"
></app-header>
<app-sidebar
  *ngIf="userDetails.role == 'admin' || userDetails.role == 'editor'"
  [isSideNavCollapsed]="isSideNavCollapsed"
  (onToggleSidenav)="onToggleSidenav($event)"
></app-sidebar>

<div
  class="body"
  [class.m-0]="userDetails?.role == 'admin' || userDetails?.role == 'editor'"
>
  <div class="position-relative">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  *ngIf="
    isSideNavCollapsed &&
    check &&
    (userDetails.role == 'admin' || userDetails.role == 'editor')
  "
  class="admin-overlay"
  (click)="dismissOverlay()"
></div>
